import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Card,
  CardMedia,
} from "@mui/material";

const CustomFishSubmission = () => {
  const [formData, setFormData] = useState({
    minecraftUsername: "",
    fishName: "",
    fishDescription: "",
    base64Texture: "",
  });

  const [previewUrl, setPreviewUrl] = useState("");
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // If the base64 texture changes, reset the preview
    if (name === "base64Texture") {
      setIsPreviewVisible(false);
    }
  };

  // Generate preview of the Minecraft head
  const handleGeneratePreview = () => {
    if (!formData.base64Texture) return;

    try {
      // The URL format for a custom head with base64 texture
      setPreviewUrl(`data:image/png;base64,${formData.base64Texture}`);
      setIsPreviewVisible(true);
    } catch (error) {
      console.error("Error generating preview:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log("Form submitted with data:", formData);
    // You could add axios call or fetch here to send the data
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Custom Fish Submission
        </Typography>

        <Paper elevation={3} sx={{ p: 4 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Minecraft Username"
                  name="minecraftUsername"
                  value={formData.minecraftUsername}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Fish Name"
                  name="fishName"
                  value={formData.fishName}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Fish Description and Extra Info"
                  name="fishDescription"
                  value={formData.fishDescription}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Base64 Fish Head Texture"
                  name="base64Texture"
                  value={formData.base64Texture}
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={3}
                  helperText="Paste the Base64 encoded texture for the fish head"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleGeneratePreview}
                  disabled={!formData.base64Texture}
                  fullWidth
                >
                  Preview Fish Head
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit Fish
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>

        {isPreviewVisible && (
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              Fish Head Preview
            </Typography>
            <Card
              sx={{
                maxWidth: 200,
                margin: "0 auto",
                p: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CardMedia
                component="img"
                image={previewUrl}
                alt="Fish Head Preview"
                sx={{
                  height: 100,
                  width: 100,
                  objectFit: "contain",
                  imageRendering: "pixelated",
                }}
              />
            </Card>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default CustomFishSubmission;
