import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Alert } from "@mui/material";

function MenuIpCopySnackbar() {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText("mcverse.city");
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Copied IP to clipboard
        </Alert>
      </Snackbar>

      <Tooltip title="Click to Copy IP">
        <IconButton onClick={handleClick} sx={{ p: 0 }}>
          <Avatar
            alt="Remy Sharp"
            src={process.env.PUBLIC_URL + "/icon-world.jpg"}
          />
        </IconButton>
        <Typography
          variant="button"
          onClick={handleClick}
          noWrap
          sx={{
            mr: 2,
            ml: 2,
            display: { xs: "none", md: "inline-flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".05rem",
            color: "#fff",
            textDecoration: "none",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          IP: MCVerse.City
        </Typography>
      </Tooltip>
    </div>
  );
}
export default MenuIpCopySnackbar;
