import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

export default function Footer() {
  return (
    <Container sx={{ py: 8 }} maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item key="footer-links" xs={6} sm={3} md={3}>
          <Paper sx={{ p: 6 }} component="footer">
            <Typography
              variant="h6"
              align="center"
              fontWeight="bold"
              gutterBottom
            >
              Links
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="text.secondary"
              component="p"
            >
              <p>
                <a href="https://discord.gg/4z6bf9sy44">Discord</a>
              </p>
              <p>
                <a href="https://map.mcverse.city/">Map</a>
              </p>
              <p>
                <a href="https://mcverse-store.tebex.io/category/2550414">
                  Store
                </a>
              </p>
            </Typography>
          </Paper>
        </Grid>

        <Grid item key="footer-disclaimer" xs={6} sm={3} md={3}>
          <Paper sx={{ p: 6 }} component="footer">
            <Typography variant="p" align="center" gutterBottom>
              Not an official Minecraft Service. <br />
              <br />
              Not approved by Mojang or Microsoft
            </Typography>
          </Paper>
        </Grid>

        <Grid item key="footer-top-sites" xs={6} sm={3} md={3}>
          <Paper sx={{ p: 6 }} component="footer">
            <Typography
              variant="h6"
              align="center"
              fontWeight="bold"
              gutterBottom
            >
              Top Sites
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="text.secondary"
              component="p"
            >
              <p>
                <a href="https://minecraft-server.net/category/Survival/">
                  Surival Minecraft Servers
                </a>
              </p>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
