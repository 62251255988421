import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Stack,
  Divider,
  Link,
} from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import TimelineIcon from "@mui/icons-material/Timeline";
import PeopleIcon from "@mui/icons-material/People";
import Papa from "papaparse";
import MarketsDataByMonth from "../Components/MarketsDataByMonth";
import VillagerShopData from "../Components/VillagerShopData";
import PlayerMarketsDataTable from "../Components/PlayerMarketsDataTable";

function Markets() {
  const [marketData, setMarketData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/markets-data/2025-04-07.tsv");
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }

        const text = await response.text();

        Papa.parse(text, {
          header: true,
          delimiter: "\t",
          complete: (results) => {
            setMarketData(results.data);
            setLoading(false);
          },
          error: (error) => {
            setError(`Error parsing data: ${error.message}`);
            setLoading(false);
          },
        });
      } catch (error) {
        setError(`Error fetching data: ${error.message}`);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, py: 4 }}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
            <StorefrontIcon fontSize="large" color="primary" />
            <Box>
              <Typography variant="h4" gutterBottom sx={{ mb: 0 }}>
                Markets Info
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Player Mined and Traded Blocks Data
              </Typography>
            </Box>
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" gutterBottom>
            If you are looking to buy blocks on MCVerse, you'll have to find a
            player who is selling it. It's a bit of a grind, yes!
            <br />
            <br />
            Markets allow sellers to sell in bulk while they are offline.
            <br />
            <br />
            Villagers Shops can act as Buyers/Sellers in your claims.
          </Typography>
          <br />

          <Paper
            elevation={1}
            sx={{ p: 2, mb: 3, bgcolor: "background.paper" }}
          >
            <Typography variant="h6" gutterBottom>
              Table of Contents
            </Typography>
            <Stack spacing={1}>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection("market-data")}
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <ShoppingBasketIcon fontSize="small" color="primary" /> Market
                Data
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection("market-trends")}
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <TimelineIcon fontSize="small" color="primary" /> Market Trends
              </Link>
              <Link
                component="button"
                variant="body1"
                onClick={() => scrollToSection("villager-shops")}
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <PeopleIcon fontSize="small" color="primary" /> Villager Shops
              </Link>
            </Stack>
          </Paper>
        </Paper>

        <div id="market-data">
          <PlayerMarketsDataTable
            marketData={marketData}
            loading={false}
            error={error}
            title="Player Markets Data"
            subtitle="Market data from 2025-04-07"
          />
        </div>

        <div id="market-trends">
          <MarketsDataByMonth
            title="Market Data Stats"
            subtitle="Market data from 2025-04-07"
          />
        </div>
      </Container>

      <div id="villager-shops">
        <VillagerShopData />
      </div>
    </Box>
  );
}

export default Markets;
