import React from "react";
import { Box, Container, Typography, Paper, Link } from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import GuideSection from "../Components/GuideSection";
import GuideContents from "../Components/GuideContents";
import ScrollTop from "../Components/ScrollTop";
import GUIDE_CONFIG from "../config/GuideConfig";

function Guide() {
  return (
    <Box sx={{ flexGrow: 1, py: 4 }}>
      {/* Quick Navigation Table of Contents */}
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, bgcolor: "#f8f8ff" }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <MenuBookIcon sx={{ fontSize: 36, mr: 2, color: "primary.main" }} />
            <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 0 }}>
              Server Guide Contents
            </Typography>
          </Box>

          <Typography variant="body1" paragraph>
            Welcome to MCVerse! Use this guide to help you get started and learn
            about our server features.
            <br />
            <br />
            Don't get overwhelmed! Besides&nbsp;
            <Link href="#getting-started" underline="hover" color="primary">
              Getting Started
            </Link>
            , the features of the server are optional and opt-in!
            <br />
            <br />
            We are a <strong>SURVIVAL-FIRST</strong> server. No custom items,
            enchantments, or skills.
          </Typography>
          <br />
          {/* Using the GuideContents component */}
          <GuideContents sections={GUIDE_CONFIG.contentSections} />
        </Paper>
      </Container>

      {/* Getting Started Guide */}
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 3,
            }}
            id="getting-started"
          >
            <MenuBookIcon
              sx={{
                fontSize: 40,
                mr: 2,
                color: "primary.main",
              }}
            />
            <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 0 }}>
              Getting Started
            </Typography>
          </Box>

          <Typography
            variant="body1"
            paragraph
            sx={{
              backgroundColor: "rgba(33, 92, 156, 0.05)",
              p: 2,
              borderRadius: 2,
              border: "1px solid rgba(33, 92, 156, 0.1)",
              mb: 4,
            }}
          >
            When first joining the server, it's encouraged that you set up a
            solo camp for yourself or the friends you joined with.
            <br />
            <br /> It takes time to meet the community and you'll want a safe
            bed to respawn to. Use <code>/sethome</code> to create a spawn point
            for yourself.
            <br />
            <br />
            <strong>Don't worry, you can change this later!</strong> <br />
            <br />
            Free Ranks include up to 5 homes, bed, clan home, and 2 player
            warps. Over 250k claim blocks.
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 4,
              mb: 4,
              rowGap: 10,
            }}
          >
            {/* Finding Land Section */}
            <GuideSection {...GUIDE_CONFIG.sections.findingLand} />

            {/* Claiming Land Section */}
            <GuideSection {...GUIDE_CONFIG.sections.claimingLand} />

            {/* Teleportation Section */}
            <GuideSection
              {...GUIDE_CONFIG.sections.teleportation}
              sx={{ mb: 2 }}
            />

            {/* Death Chests Section */}
            <GuideSection
              {...GUIDE_CONFIG.sections.deadChests}
              sx={{ mb: 2 }}
            />

            {/* Ranks & Progression Section */}
            <GuideSection
              {...GUIDE_CONFIG.sections.ranksProgression}
              sx={{ mb: 2 }}
            />
          </Box>
        </Paper>
      </Container>

      {/* Advanced Features Container */}
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", mb: 3 }}
            id="advanced"
          >
            <MenuBookIcon sx={{ fontSize: 40, mr: 2, color: "primary.main" }} />
            <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 0 }}>
              Advanced
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 4,
              mb: 4,
              rowGap: 10,
            }}
          >
            {/* Chat Tips Section */}
            <GuideSection {...GUIDE_CONFIG.sections.chatTips} />

            {/* Claim Tips Section */}
            <GuideSection {...GUIDE_CONFIG.sections.claimTips} />

            {/* Advanced Teleportation Section */}
            <GuideSection {...GUIDE_CONFIG.sections.advancedTeleportation} />

            {/* Pocket Worlds Section */}
            <GuideSection {...GUIDE_CONFIG.sections.pocketWorlds} />

            {/* Misc Commands Section */}
            <GuideSection {...GUIDE_CONFIG.sections.miscCommands} />
          </Box>
        </Paper>
      </Container>

      {/* Economy Container */}
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", mb: 3 }}
            id="economy"
          >
            <MenuBookIcon sx={{ fontSize: 40, mr: 2, color: "primary.main" }} />
            <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 0 }}>
              Economy
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 4,
              mb: 4,
              rowGap: 10,
            }}
          >
            {/* Economy Basics Section */}
            <GuideSection {...GUIDE_CONFIG.sections.economybasics} />

            {/* Markets Section */}
            <GuideSection {...GUIDE_CONFIG.sections.economymarkets} />

            {/* Gifting Section */}
            <GuideSection {...GUIDE_CONFIG.sections.economygifting} />

            {/* Silk Spawners Section */}
            <GuideSection {...GUIDE_CONFIG.sections.silkspawners} />

            {/* Real Estate Section */}
            <GuideSection {...GUIDE_CONFIG.sections.economyrealestate} />

            {/* Real Estate Section */}
            <GuideSection {...GUIDE_CONFIG.sections.economyhotels} />
          </Box>
        </Paper>
      </Container>

      {/* Events Container */}
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", mb: 3 }}
            id="events"
          >
            <MenuBookIcon sx={{ fontSize: 40, mr: 2, color: "primary.main" }} />
            <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 0 }}>
              Events
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 4,
              mb: 4,
              rowGap: 10,
            }}
          >
            {/* Fishing Section */}
            <GuideSection {...GUIDE_CONFIG.sections.fishing} />

            {/* Dragon Slayer Section */}
            <GuideSection {...GUIDE_CONFIG.sections.dragonSlayer} />

            {/* Teaming Up Section */}
            <GuideSection {...GUIDE_CONFIG.sections.teamingUp} />
          </Box>
        </Paper>
      </Container>

      {/* Builder Tools Container */}
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", mb: 3 }}
            id="builder-tools"
          >
            <MenuBookIcon sx={{ fontSize: 40, mr: 2, color: "primary.main" }} />
            <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 0 }}>
              Builder Tools
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 4,
              mb: 4,
              rowGap: 10,
            }}
          >
            {/* Armour Stands Section */}
            <GuideSection {...GUIDE_CONFIG.sections.armourStands} />

            {/* Image Frames Section */}
            <GuideSection {...GUIDE_CONFIG.sections.imageFrames} />

            {/* Block Heads Section */}
            <GuideSection {...GUIDE_CONFIG.sections.blockHeads} />

            {/* Mini Blocks Section */}
            <GuideSection {...GUIDE_CONFIG.sections.miniBlocks} />

            {/* Big Doors Section */}
            <GuideSection {...GUIDE_CONFIG.sections.bigDoors} />

            {/* Portals Section */}
            <GuideSection {...GUIDE_CONFIG.sections.portals} />
          </Box>
        </Paper>
      </Container>

      {/* Clans and PvP Container */}
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", mb: 3 }}
            id="clans-pvp"
          >
            <MenuBookIcon sx={{ fontSize: 40, mr: 2, color: "primary.main" }} />
            <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 0 }}>
              Clans and PvP
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 4,
              mb: 4,
              rowGap: 10,
            }}
          >
            {/* Clan Basics Section */}
            <GuideSection {...GUIDE_CONFIG.sections.clanBasics} />

            {/* PvP Section */}
            <GuideSection {...GUIDE_CONFIG.sections.pvp} />

            {/* Clan Wars Section */}
            <GuideSection {...GUIDE_CONFIG.sections.clanWars} />

            {/* Castle Wars Section */}
            <GuideSection {...GUIDE_CONFIG.sections.castleWars} />
          </Box>
        </Paper>
      </Container>

      {/* Scroll to Top Button */}
      <ScrollTop />
    </Box>
  );
}

export default Guide;
