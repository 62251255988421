import React from "react";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ExploreIcon from "@mui/icons-material/Explore";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import WarningIcon from "@mui/icons-material/Warning";
import StarIcon from "@mui/icons-material/Star";
import PaidIcon from "@mui/icons-material/Paid";
import InventoryIcon from "@mui/icons-material/Inventory";
import StorefrontIcon from "@mui/icons-material/Storefront";
import BugReportIcon from "@mui/icons-material/BugReport";
import HouseIcon from "@mui/icons-material/House";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import SetMealIcon from "@mui/icons-material/SetMeal";
import SecurityIcon from "@mui/icons-material/Security";
import GroupsIcon from "@mui/icons-material/Groups";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import ImageIcon from "@mui/icons-material/Image";
import FaceIcon from "@mui/icons-material/Face";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import DoorFrontIcon from "@mui/icons-material/DoorFront";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ChatIcon from "@mui/icons-material/Chat";
import LinkIcon from "@mui/icons-material/Link";
import RoomServiceIcon from "@mui/icons-material/RoomService";

// Guide configuration - All content defined here
const GUIDE_CONFIG = {
  // Table of contents sections
  contentSections: [
    {
      title: "Getting Started",
      links: [
        { text: "Finding Land", href: "#finding-land" },
        { text: "Claiming Land", href: "#claiming-land" },
        { text: "Getting Around", href: "#teleportation" },
        { text: "Dead Chests", href: "#dead-chests" },
        { text: "Ranks & Progression", href: "#ranks-progression" },
      ],
    },
    {
      title: "Advanced",
      id: "advanced",
      links: [
        { text: "Chat Tips", href: "#chat-tips" },
        { text: "Claim Tips", href: "#claim-tips" },
        { text: "Teleportation", href: "#advanced-tp" },
        { text: "Pocket (Resource) Worlds", href: "#pocket-worlds" },
        { text: "More Commands", href: "#misc-commands" },
        //  /sit, /recipe, /hat, /stats, /nerfphantom,
      ],
    },
    {
      title: "Economy",
      links: [
        { text: "Basics You Should Know", href: "#economybasics" },
        { text: "Markets", href: "#economymarkets" },
        { text: "Gifting", href: "#economygifting" },
        { text: "Silk Spawners", href: "#silkspawners" },
        { text: "Real Estate", href: "#economyrealestate" },
        { text: "Hotels", href: "#economyhotels" },
      ],
    },
    {
      title: "Events",
      links: [
        { text: "Fishing Competitions", href: "#fishing" },
        { text: "Dragon Slayer", href: "#dragon-slayer" },
        { text: "Teaming Up", href: "#teaming-up" },
      ],
    },
    {
      title: "Builder Tools",
      links: [
        { text: "Armour Stands", href: "#armourstands" },
        { text: "Image Frame Tools", href: "#imageframes" },
        { text: "Player and Mob Heads", href: "#block-heads" },
        { text: "Mini Blocks", href: "#miniblocks" },
        { text: "Big Doors", href: "#bigdoors" },
        { text: "Custom Portals", href: "#portals" },
      ],
    },
    {
      title: "Clans and PvP",
      links: [
        { text: "Clan Basics", href: "#clanbasics" },
        { text: "PvP and Arenas", href: "#pvp" },
        { text: "Clan Wars", href: "#clanwars" },
        { text: "Castle Wars", href: "#castle-wars" },
      ],
    },
  ],

  // Detailed content for each section
  sections: {
    // Getting Started sections
    findingLand: {
      icon: <ExploreIcon />,
      title: "Finding Land",
      id: "finding-land",
      items: [
        {
          text: (
            <>
              Use <code>/wild</code> to teleport to a random spot
            </>
          ),
        },
        {
          text: "Check the map if you're looking for a specific biome or want to ensure there are no bases nearby",
        },
        {
          text: "You can also use player warps to teleport, or walk!",
        },
        {
          text: "Make sure you sleep or sethome to save your spawn",
        },
      ],
    },

    claimingLand: {
      icon: <HomeWorkIcon />,
      title: "Claiming Land",
      id: "claiming-land",
      items: [
        {
          text: "It's important to claim your land, especially before inviting strangers over to your base",
        },
        {
          text: "Earn 1000 claimblocks per hour, by ranking up after 1 week, vote rewards, or purchase",
        },
        {
          text: "Use a Golden Shovel and Stick to visually see your claim. You can also /extendclaim and /claim <radius>",
        },
      ],
    },

    teleportation: {
      icon: <MyLocationIcon />,
      title: "Getting Around",
      id: "teleportation",
      items: [
        {
          text: "Teleportation comes at a small cost, encouraging survival and exploration the classic way",
        },
        {
          text: (
            <>
              Use <code>/home bed</code> and <code>/home</code> to go home.{" "}
              <br />
              Make sure to <code>/sethome</code> to set your personal spawn
            </>
          ),
        },
        {
          text: (
            <>
              Visit Player warps with <code>/pwarp</code>, teleporting you to
              locations created by other players
            </>
          ),
        },
        {
          text: (
            <>
              You can also <code>/warp</code> and <code>/tpa</code> to other
              players
            </>
          ),
        },
        {
          text: "Warning! Teleporting out of the Nether and the End is blocked",
          customIcon: <WarningIcon sx={{ color: "warning.main" }} />,
          textProps: {
            color: "warning.dark",
            fontWeight: "medium",
          },
        },
      ],
    },

    deadChests: {
      icon: <InventoryIcon />,
      title: "Dead Chests",
      id: "dead-chests",
      items: [
        {
          text: "When you die, your items are stored in a dead chest at your location of death. Even in lava or the void!",
        },
        {
          text: "Your dead chest is protected for 72 hours",
        },
        {
          text: (
            <>
              Use <code>/dc list</code> to list your chests
            </>
          ),
        },
        {
          text: "Items in your death chest will drop to the ground when expired",
        },
      ],
    },

    // Server Systems sections
    ranksProgression: {
      icon: <StarIcon />,
      title: "Ranks & Progression",
      id: "ranks-progression",
      items: [
        {
          text: (
            <>
              After one week of playing, you'll rank up to the{" "}
              <strong>Regular</strong> rank
            </>
          ),
        },
        {
          text: "This gives you your first pwarp and a total of 3 sethomes (plus your bed), and a claim block boost",
        },
        {
          text: "The Citizen rank is purchased with in-game currency. It is available after 2 weeks. It also comes with a claim block boost.",
        },
        {
          text: "Citizens get a discount 100k claims blocks and 2 stacks of TNT a month in the /voteshop",
        },
      ],
    },

    economybasics: {
      icon: <PaidIcon />,
      title: "Economy Basics",
      id: "economybasics",
      items: [
        {
          text: "You don't need to worry about money to play survival",
        },
        {
          text: "Earn $1250 a day by play time",
        },
        {
          text: "You can also vote for the server to earn more",
        },
        {
          text: (
            <>
              There is a limited <code>/warp sellshop</code> but you'll get
              better prices selling to players
            </>
          ),
        },
        {
          text: (
            <>
              You can buy/sell using the <code>/markets</code> or Villager Shops
            </>
          ),
        },
        {
          text: "Earn extra by killing the Ender Dragon or selling Competition Fish",
        },
      ],
    },

    economymarkets: {
      icon: <StorefrontIcon />,
      title: "Markets",
      id: "economymarkets",
      items: [
        {
          text: "Markets allow players to sell and buy blocks while offline. Share your oversupply to help complete builds. Assist player's in a desperate pinch..",
        },
        {
          text: (
            <>
              Browse and Create a Market with <code>/markets</code>
            </>
          ),
        },
        {
          text: (
            <>
              Search for specific items using <code>/markets search item</code>
            </>
          ),
        },
        {
          text: "Set up villager shops to sell items to other players. There is one for free in the /voteshop (Citizens)",
        },
        {
          text: "The markets are player run and dynamic. If you can't find what you're looking for, consider starting a factory and getting rich",
        },
      ],
    },

    silkspawners: {
      icon: <BugReportIcon />,
      title: "Silk Spawners",
      id: "silkspawners",
      items: [
        {
          text: "Mine spawners with Silk Touch enchantment to obtain them",
        },
        {
          text: "Place spawners in your base to create mob farms",
        },
        {
          text: "Spawners are valuable items in the player economy",
        },
        {
          text: "Spawners will be upgradeable in time",
        },
      ],
    },

    economyrealestate: {
      icon: <HouseIcon />,
      title: "Real Estate",
      id: "economyrealestate",
      items: [
        {
          text: "Buy and sell land claims using signs",
        },
        {
          text: "Improve and sell your property. No capital gains tax on your primary residence",
        },
        {
          text: "Create rental properties for passive income",
        },
        {
          text: "Set up farms, shops, trading halls, in high-traffic areas for better business",
        },
        {
          text: "Claim Blocks do transfer upon successful transactions. For large deals, consider a Third Party Agent",
        },
      ],
    },

    economyhotels: {
      icon: <RoomServiceIcon />,
      title: "Hotels",
      id: "economyhotels",
      items: [
        {
          text: "By request it is possible to setup a hotel",
        },
        {
          text: "Hotels allow you to offer a room a player can teleport back to",
        },
        {
          text: "Normally they are safe to afk in, come with storage, and potentially offer amenities",
        },
      ],
    },

    economygifting: {
      icon: <CardGiftcardIcon />,
      title: "Gifting",
      id: "economygifting",
      items: [
        {
          text: (
            <>
              Use <code>/gift [player]</code> to gift the item you are holding
            </>
          ),
        },
        {
          text: "Consider first asking new players if they want help. Respect the grind",
        },
        {
          text: "You can also be gracious and help players in a pinch, without actaully tping to them",
        },
        {
          text: "You can disable receiving gifts with /gifttoggle and /giftblock",
        },
      ],
    },

    // Events sections
    fishing: {
      icon: <SetMealIcon />,
      title: "Fishing Competitions",
      id: "fishing",
      items: [
        {
          text: "Fishing Competitions are a fun way to earn some cash and take a break from the grind",
        },
        {
          text: "Catch rare fish that can be displayed or worn on your head",
        },
        {
          text: "Sell fishing with /emf shop or /emf sellall",
        },
        {
          text: "Use baits to increase your odds of winning and catching bigger fish",
        },
      ],
    },

    dragonSlayer: {
      icon: <SecurityIcon />,
      title: "Dragon Slayer",
      id: "dragon-slayer",
      items: [
        {
          text: "Team up with other players to defeat the Ender Dragon",
        },
        {
          text: "Dragon respawns are scheduled events on the server",
        },
        {
          text: "Earn cash and get the Dragon Egg in the Pocket End",
        },
        {
          text: "Become the Dragon Slayer and /dsl setmystatue",
        },
      ],
    },

    teamingUp: {
      icon: <GroupsIcon />,
      title: "Teaming Up",
      id: "teaming-up",
      items: [
        {
          text: "It takes time to meet the community. Look out for new players looking to team",
        },
        {
          text: "Join clans to team up with other players",
        },
        {
          text: "Share resources and collaborate on builds",
        },
        {
          text: "Consider using discord to try and find someone looking for your play style",
        },
      ],
    },

    // Builder Tools sections
    armourStands: {
      icon: <AccessibilityNewIcon />,
      title: "Armour Stands",
      id: "armourstands",
      items: [
        {
          text: (
            <>
              Use <code>/eas give</code> to access armour stand editor as a
              Regular Member.
            </>
          ),
        },
        {
          text: "Create custom poses for decoration",
        },
        {
          text: "Display armor and weapons on stands",
        },
        {
          text: "Build realistic scenes with posed armor stands",
        },
      ],
    },

    imageFrames: {
      icon: <ImageIcon />,
      title: "Image Frame Tools",
      id: "imageframes",
      items: [
        {
          text: (
            <>
              Use <code>/imageframe create [name] [url] [width] [height]</code>{" "}
              to create maps from a url.
            </>
          ),
        },
        {
          text: "Crop Website: Upload Website:",
        },
        {
          text: "Toggle frame visibility with [/itf toggle visibility] (Regular Members)",
        },
      ],
    },

    blockHeads: {
      icon: <FaceIcon />,
      title: "Player and Mob Heads",
      id: "block-heads",
      items: [
        {
          text: "Collect player and mob heads for decoration",
        },
        {
          text: "Kill mobs with an AXE for a 5% drop chance. Vanila mobs that already drop heads follow the vanilla mechanics",
        },
        {
          text: "Fish out custom fish heads and your player head",
        },
        {
          text: "You can also get your player head from the vote shop. PvP options may come soon",
        },
      ],
    },

    miniBlocks: {
      icon: <ViewComfyIcon />,
      title: "Mini Blocks",
      id: "miniblocks",
      items: [
        {
          text: "Craft mini versions of normal blocks",
        },
        {
          text: "Use mini blocks for detailed decoration",
        },
        {
          text: "Trade mini blocks with other players",
        },
        {
          text: "Redeem and craft with /voteshop or premium ranks.",
        },
      ],
    },

    bigDoors: {
      icon: <DoorFrontIcon />,
      title: "Big Doors",
      id: "bigdoors",
      items: [
        {
          text: (
            <>
              Big Doors can be requested for $10,000 to $50,000 to create big
              doors
            </>
          ),
        },
        {
          text: "Build massive working doors for your base",
        },
        {
          text: "Create drawbridges and portcullises",
        },
        {
          text: "Doors can have secret keys and triggers",
        },
      ],
    },

    portals: {
      icon: <LinkIcon />,
      title: "Custom Portals",
      id: "portals",
      items: [
        {
          text: "Link two areas together with a custom physical portal.",
        },
        { text: "In Game Currency Cost may apply" },
      ],
    },

    // Clans and PvP sections
    clanBasics: {
      icon: <LocalPoliceIcon />,
      title: "Clan Basics",
      id: "clanbasics",
      items: [
        {
          text: (
            <>
              Create a clan with <code>/clan create [name]</code>
            </>
          ),
        },
        {
          text: "Invite players to your clan with /clan invite",
        },
        {
          text: "Set up /clan home and shared resources",
        },
        {
          text: "Communicate in clan chat with /. or /ally",
        },
      ],
    },

    pvp: {
      icon: <SportsKabaddiIcon />,
      title: "PvP and Arenas",
      id: "pvp",
      items: [
        {
          text: "PvP is optional. Even with PvP enabled, players are protected in their claims unless in times of War.",
        },
        {
          text: (
            <>
              Use <code>/pvp</code> to toggle your PvP status in the wild.
            </>
          ),
        },
        {
          text: "Fight in the [/warp arena] without losing items or durability",
        },
        {
          text: "Participate in scheduled PvP tournaments (Coming Soon)",
        },
      ],
    },

    clanWars: {
      icon: <MilitaryTechIcon />,
      title: "Clan Wars",
      id: "clanwars",
      items: [
        {
          text: "Clan Wars are a mutual competition with serious stakes",
        },
        {
          text: "There have been 0 wars on the server",
        },
        {
          text: "/warp bg1 or similar can have rules adjusted to meet your competition needs, including item drops",
        },
        {
          text: "The server will accomidate reasonable requests, such as fascilitating destructable battles in claims",
        },
      ],
    },

    castleWars: {
      icon: <AccountBalanceIcon />,
      title: "Castle Wars",
      id: "castle-wars",
      items: [
        {
          text: "A server-wide castle wars event that is coming soon",
        },
      ],
    },

    // Advanced sections
    chatTips: {
      icon: <ChatIcon />,
      title: "Chat Tips",
      id: "chat-tips",
      items: [
        {
          text: "Use /ts to toggle shout and chat in local chat",
        },
        {
          text: "Use /msg or /w to privately message other players.",
        },
        {
          text: "Quick reply with /r",
        },
        {
          text: "Join clan chat with /. join",
        },
        {
          text: "Use /ignore to block messages from specific players",
        },
        {
          text: "Show your [item] and [inv] in chat",
        },
        {
          text: "Send offline messages with /mail",
        },
      ],
    },

    claimTips: {
      icon: <HomeWorkIcon />,
      title: "Claim Tips",
      id: "claim-tips",
      items: [
        {
          text: "Use a golden shovel to create and modify land claims (Right Click)",
        },
        {
          text: "Use a stick to show claim info (Right Click)",
        },
        {
          text: "Add trusted friends to your claim with /trust [player]",
        },
        {
          text: "You can also use /containertrust, /accesstrust, /permissiontrust",
        },
        {
          text: "Kick and ban using /kickfromclaim and /banfromclaim",
        },
        {
          text: "Enable TNT or creeper explosions with /claimexplosions",
        },
        {
          text: "Set custom settings with /setclaimflag and /listflags",
        },
        {
          text: "Use /abandonallclaims to remove all your claims",
        },
      ],
    },

    advancedTeleportation: {
      icon: <MyLocationIcon />,
      title: "Advanced Teleportation",
      id: "advanced-tp",
      items: [
        {
          text: "Save teleport costs by using Ender Pearls or traditional Nether travel",
        },
        {
          text: "Set up multiple homes with different names using /sethome [name]",
        },
        {
          text: "Use /tpa and /tpahere to request to teleport to players or players to you",
        },
        {
          text: "Create public warp points with /pwarp create [name] (Regular Members)",
        },
      ],
    },

    pocketWorlds: {
      icon: <ExploreIcon />,
      title: "Pocket (Resource) Worlds",
      id: "pocket-worlds",
      items: [
        {
          text: "/jump into the resource pocket world. From there you can nether portal into the Pocket Nether.",
        },
        {
          text: "The Pocket End is accessed from the Permanent End. Simply find any stronghold. Hint: There is one at spawn and there are pwarps.",
        },
        {
          text: "Resource worlds reset periodically, so don't build permanent structures there",
        },
      ],
    },

    miscCommands: {
      icon: <MenuBookIcon />,
      title: "More Commands",
      id: "misc-commands",
      items: [
        {
          text: (
            <>
              Use <code>/sit</code> to sit on stairs and slabs
            </>
          ),
        },
        {
          text: (
            <>
              Look up crafting recipes with <code>/recipe</code>
            </>
          ),
        },
        {
          text: (
            <>
              Wear any item as a hat with <code>/hat</code>
            </>
          ),
        },
        {
          text: (
            <>
              View and Share statistics with <code>/stats</code>
            </>
          ),
        },
        {
          text: (
            <>
              Reduce phantom spawns with <code>/nerfphantom toggle</code>{" "}
              (Redeem in /voteshop or premium ranks)
            </>
          ),
        },
      ],
    },
  },
};

export default GUIDE_CONFIG;
