import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function PlayerBuilds() {
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleClickOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % itemData.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + itemData.length) % itemData.length
    );
  };

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrev();
      }
    };

    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  const itemData = [
    {
      img: "imgs/player-builds/2025-01-19_14.33.54.jpg",
      title: "__Lewis",
      rows: 2,
      cols: 4,
    },
    {
      img: "imgs/player-builds/2023-08-05_11.03.15.jpg",
      title: "EcS Base",
      rows: 2,
      cols: 2,
    },
    {
      img: "imgs/player-builds/2024-01-22_22.11.43.jpg",
      rows: 1,
      cols: 2,
    },
    {
      img: "imgs/player-builds/2023-08-11_14.26.41.jpg",
      title: "BugsBunny93",
    },
    {
      img: "imgs/player-builds/2023-09-14_08.32.10.jpg",
    },
    {
      img: "imgs/player-builds/2025-02-23_02.40.29.jpg",
      rows: 2,
      cols: 2,
    },
    {
      img: "imgs/player-builds/2024-01-10_15.51.39.jpg",
      rows: 2,
      cols: 2,
    },
    {
      img: "imgs/player-builds/2024-01-17_19.58.54.jpg",
      rows: 2,
      cols: 2,
    },
    {
      img: "imgs/player-builds/2024-02-19_22.34.37.jpg",
    },
    {
      img: "imgs/player-builds/2024-02-19_22.39.54.jpg",
    },
    {
      img: "imgs/player-builds/2025-01-28_23.32.39.jpg",
    },
    {
      img: "imgs/player-builds/2024-02-29_22.51.45.jpg",
    },
    {
      img: "imgs/player-builds/DrWho.jpg",
      cols: 2,
    },
    {
      img: "imgs/player-builds/House.jpg",
      rows: 1,
      cols: 2,
    },
    {
      img: "imgs/player-builds/hsh.jpg",
      rows: 2,
      cols: 2,
    },
    {
      img: "imgs/player-builds/image0.jpg",
      rows: 2,
      cols: 1,
    },
    {
      img: "imgs/player-builds/MinecraftChillin.jpg",
    },
    {
      img: "imgs/player-builds/Night_.jpg",
    },
    {
      img: "imgs/player-builds/preview.jpg",
      rows: 2,
      cols: 2,
    },
    {
      img: "imgs/player-builds/fishing_getaway.jpg",
      rows: 2,
      cols: 2,
    },
    {
      img: "imgs/player-builds/2025-01-25_23.29.33.jpg",
      rows: 2,
      cols: 4,
    },
    {
      img: "imgs/player-builds/2024-11-09_21.10.32.jpg",
      rows: 2,
      cols: 2,
    },
    {
      img: "imgs/player-builds/2025-01-28_15.56.43.jpg",
      rows: 1,
      cols: 1,
    },
    {
      img: "imgs/player-builds/Screenshot_2025-02-04_030456.jpg",
      rows: 1,
      cols: 1,
    },
    {
      img: "imgs/player-builds/2025-02-11_01.53.46.jpg",
      rows: 1,
      cols: 2,
    },
  ];

  return (
    <Box>
      <Typography
        component="h3"
        variant="h3"
        color="text.primary"
        gutterBottom
        marginTop="40px"
      >
        Player Builds
      </Typography>
      <Typography>
        Submit your own in discord build-share channel.
        <br />
        Built under vanilla survival rules.
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <ImageList
              sx={{ width: "100%", height: "100%" }}
              variant="quilted"
              cols={4}
              rowHeight={240}
            >
              {itemData.map((item, index) => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                  onClick={() => handleClickOpen(index)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
            padding: 1,
          }}
        >
          <CloseIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <DialogContent>
          {itemData[currentIndex] && (
            <>
              <img
                src={itemData[currentIndex].img}
                alt="Full size"
                style={{ width: "100%", height: "auto" }}
              />
              {itemData[currentIndex].title && (
                <Typography
                  variant="h6"
                  color="text.secondary"
                  align="center"
                  sx={{ marginTop: 2 }}
                >
                  {itemData[currentIndex].title}
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <IconButton
          aria-label="previous"
          onClick={handlePrev}
          sx={{
            position: "absolute",
            left: 8,
            top: "50%",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
            padding: 1,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <IconButton
          aria-label="next"
          onClick={handleNext}
          sx={{
            position: "absolute",
            right: 8,
            top: "50%",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
            padding: 1,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Dialog>
    </Box>
  );
}
export default PlayerBuilds;
