import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Chip,
  useTheme,
  alpha,
  IconButton,
  Tooltip,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StorefrontIcon from "@mui/icons-material/Storefront";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const PlayerMarketsDataTable = ({
  marketData = [],
  loading = false,
  error = null,
  title = "Player Markets Data",
  subtitle = "",
}) => {
  const theme = useTheme();
  const [orderBy, setOrderBy] = useState("item_type");
  const [order, setOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const createSortHandler = (property) => () => {
    handleRequestSort(property);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => {
          // Handle numeric values
          if (
            !isNaN(parseFloat(a[orderBy])) &&
            !isNaN(parseFloat(b[orderBy]))
          ) {
            return parseFloat(b[orderBy]) - parseFloat(a[orderBy]);
          }
          // Handle string values
          return b[orderBy]
            ?.toString()
            .localeCompare(a[orderBy]?.toString() || "");
        }
      : (a, b) => {
          // Handle numeric values
          if (
            !isNaN(parseFloat(a[orderBy])) &&
            !isNaN(parseFloat(b[orderBy]))
          ) {
            return parseFloat(a[orderBy]) - parseFloat(b[orderBy]);
          }
          // Handle string values
          return a[orderBy]
            ?.toString()
            .localeCompare(b[orderBy]?.toString() || "");
        };
  };

  const filteredData = marketData.filter((item) => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return (
      item.item_type?.toLowerCase().includes(query) ||
      item.top_buyer?.toLowerCase().includes(query) ||
      item.top_seller?.toLowerCase().includes(query)
    );
  });

  if (error) {
    return (
      <Card elevation={3} sx={{ mb: 4, overflow: "hidden" }}>
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: "h5" }}
          subheader={subtitle}
        />
        <Divider />
        <CardContent>
          <Typography color="error">{error}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card elevation={3} sx={{ mb: 4, overflow: "hidden" }}>
      <CardHeader
        title={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StorefrontIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            <Typography variant="h4">{title}</Typography>
          </Box>
        }
        subheader={subtitle}
        action={
          <Tooltip title="Market data statistics">
            <IconButton>
              <TrendingUpIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Divider />

      <CardContent sx={{ p: 2 }}>
        <Box sx={{ mb: 3 }}>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by item, buyer, seller..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: 400,
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
          />
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 600,
            boxShadow: "none",
            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            borderRadius: 2,
            overflow: "auto",
          }}
        >
          <Table stickyHeader aria-label="markets data table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: alpha(theme.palette.primary.main, 0.05),
                  "& th": {
                    fontWeight: "bold",
                  },
                }}
              >
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "item_type"}
                    direction={orderBy === "item_type" ? order : "asc"}
                    onClick={createSortHandler("item_type")}
                  >
                    Item Type
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === "avg_price_per_unit"}
                    direction={orderBy === "avg_price_per_unit" ? order : "asc"}
                    onClick={createSortHandler("avg_price_per_unit")}
                  >
                    Avg Price Per Unit
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === "total_sales"}
                    direction={orderBy === "total_sales" ? order : "asc"}
                    onClick={createSortHandler("total_sales")}
                  >
                    Total Sales
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === "top_buyer"}
                    direction={orderBy === "top_buyer" ? order : "asc"}
                    onClick={createSortHandler("top_buyer")}
                  >
                    Top Buyer
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === "top_buyer_amount"}
                    direction={orderBy === "top_buyer_amount" ? order : "asc"}
                    onClick={createSortHandler("top_buyer_amount")}
                  >
                    Top Buyer Amount
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === "top_seller"}
                    direction={orderBy === "top_seller" ? order : "asc"}
                    onClick={createSortHandler("top_seller")}
                  >
                    Top Seller
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === "top_seller_amount"}
                    direction={orderBy === "top_seller_amount" ? order : "asc"}
                    onClick={createSortHandler("top_seller_amount")}
                  >
                    Top Seller Amount
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from(new Array(5)).map((_, index) => (
                    <TableRow key={`skeleton-${index}`}>
                      {Array.from(new Array(7)).map((_, cellIndex) => (
                        <TableCell key={`cell-${cellIndex}`}>
                          <Skeleton animation="wave" height={24} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : filteredData
                    .sort(getComparator(order, orderBy))
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:nth-of-type(odd)": {
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.02
                            ),
                          },
                          "&:hover": {
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.05
                            ),
                          },
                          transition: "background-color 0.2s",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Chip
                              size="small"
                              label={row.item_type}
                              sx={{
                                backgroundColor: alpha(
                                  theme.palette.primary.main,
                                  0.1
                                ),
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {!isNaN(parseFloat(row.avg_price_per_unit))
                            ? parseFloat(row.avg_price_per_unit).toFixed(2)
                            : row.avg_price_per_unit}
                        </TableCell>
                        <TableCell align="right">{row.total_sales}</TableCell>
                        <TableCell align="right">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <ShoppingCartIcon
                              fontSize="small"
                              sx={{
                                mr: 0.5,
                                color: theme.palette.success.main,
                              }}
                            />
                            {row.top_buyer}
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {row.top_buyer_amount}
                        </TableCell>
                        <TableCell align="right">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <StorefrontIcon
                              fontSize="small"
                              sx={{ mr: 0.5, color: theme.palette.info.main }}
                            />
                            {row.top_seller}
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {row.top_seller_amount}
                        </TableCell>
                      </TableRow>
                    ))}
              {!loading && filteredData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="body1" sx={{ py: 3 }}>
                      No market data found matching your search criteria
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {loading
              ? "Loading data..."
              : `Showing ${filteredData.length} out of ${marketData.length} items`}
          </Typography>
          <Chip
            label={`${filteredData.length} items`}
            size="small"
            color="primary"
            variant="outlined"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default PlayerMarketsDataTable;
