import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Paper,
  InputAdornment,
  Pagination,
  Stack,
} from "@mui/material";
import { Search } from "lucide-react";

// Sample data - just an array of usernames
const SAMPLE_PLAYERS = [
  "Olafspapi",
  "DocSplinters",
  "HitmanPL47",
  "TriniNicky",
  "Bullnikle",
  "Debs6",
  "Vayetsee",
  "leifykief",
  "DroolSideways16",
  "Wo0fie",
  "BananaMan1590",
  "Wyld1",
  "CHEEKYCAT106",
  "Kingswaida",
  "ColdandLate",
  "0311Slipstream",
  "Xaviersunsun",
  "__Lewis",
  "FatPurplePickle",
  "HotRodSkater",
  "mauriceblox",
  "oFades",
  "Taciturnia",
  "Mio_Aurelio",
  "__Stryke__",
  "thor_bod",
  "Gejetozaha",
  "Wummbelbee_",
  "Ryan_2002",
  "PrimordialPaw",
  ".MuddyCIeric",
  "classy5653",
  "MossyKut",
  "LeftHandOfD",
  "UmbralPlatypus",
  "TanTan89",
  "Jordy9872",
  "NeonCat1911",
  "MrPortakal",
  "Loeffel_V2",
  "Cahee",
  "DJ_frostyflakes",
  "Flintaxe",
  "OupiGamer",
  "WoodCK",
  "xXHelmerXx",
  "PixKnight",
  "OrangeNordique",
  "Joeykoko1111",
  "ToastyCracker26",
  "Abigailxoxoxo",
  "BatFrogSRJ",
  "Zaniley",
  "KnightOwl52",
  ".bullnikle",
  "OffBrandPanda",
  "RisenPhoenix625",
  "notjukebox7",
  "SocialTech",
  "Loi10",
  "WibblyWalrus",
  "SauckoBones",
  "Gummball",
  "distrought",
  "BrickBuilder206",
  "AvalonHarto",
  "LathropYeovsky",
  "silver_trap",
  "AB_TRAINZ",
  "scorto7",
  "Mdork",
  "Efec201216",
  "itsfredi1",
  "Thysie1",
  "horaizons",
  "ScarletLeaf1000",
  "DrBeezBear",
  "Waltang",
  "ZAMUK_",
  "VietnamBomber123",
  "LOCKY914",
  "SilverKraftX",
  "FuegoDragnix",
  "JazzLekture",
  "Quickbunny75",
  "Moreminds",
  ".StodgySoup44594",
  "Jchagn",
  "Mridge9",
  "palpeh",
  "AtemporaI",
  "sweatyrayan",
  "TaxEvasion17",
  "Blender3404",
  "Amchaet",
  "JMike03",
  "stonkedman",
  "DefinitelyStupid",
  "DJLegends42",
  "himatthew",
  ".Blades13104088",
  "LeHe21",
  "NiceAndEasy",
  "KenSenior",
  "ProMasterX21",
  "Redinmybed",
  "Billy_Stacks",
  "Sanzel",
  "MomboJoe",
  "Helmi111",
  "AJMonster321",
  "sir_patrick1",
  "elementEpoch",
  "KabutoKaiga",
  "Talor5110",
  "HmansTopGuy1",
  "Vyp",
  "TikiForTheFreaky",
  "MadGamer8872",
  "Scholium",
  "Sw1tchbl4de_22",
  "hyew0n_",
  "Previ0us0wner",
  "Moccake",
  "Abyssal_Descent",
  "Adson102",
  "ToofFairy",
  "Pirate_Kun",
  "Fraden",
  "FB6SI",
  "_Tealy",
  "TheBestMemeCat",
  "VigilanceCake",
  "Maltchi",
  "knotaj",
  "Colling1",
  "Floneus",
  "HiveCity",
  "sayjrock",
  "LookAtDaveMan",
  "UnicornTwinkle24",
  "ApothecaryGeorge",
  "Samuli06",
  "Scumlord",
  "divvrse",
  "thecqueen",
  "KennyDaBoy",
  "brck_the_beast",
  "Evildragonx1",
  "domcavi",
  "CanadianGoose695",
  "adamhottie",
  "Rolle1001",
  "Chomkii",
  "MuddyCleric",
  "Rugon9123",
  "atcat_07",
  "LunaBee12",
  "Harmonious_Fox22",
  "ItsCaleb404",
  "RiftStudier",
  "Piglinz",
  "CP3HD",
  "sandy_swungg",
  "maisie_squid",
  ".WallopingBoss",
  "rickdeventer",
  "Sethraven80",
  "Nukeinacat",
  "Crutan45",
  "MoparMike",
  "MechanicalX3",
  "Dawdle_Doodles",
  "Rudeje11yfish",
  "x_Toaster_x",
  "myrinsk",
  "kylejalopi",
  "oqAree",
  "Romaunt1",
  "NotShinon",
  "jimmytro",
  "CalmDinasor796",
  "OneSlimeJelly",
  "CodeCaves",
  "Jabbathehood08",
  "BiggieFresh",
  "Azarathican",
  "Darkness0456",
  "RedShot2010",
  "elomon",
  "Cry0nix",
  "Jaebe",
  "NaoEhProfissa",
  "Luxzhv",
  "Oscar578",
  "Forest_Minecraft",
  "chibi21a",
  "Beblith",
  "Miyigi1996",
  "MinningDew",
  "Lindsaym10",
  "Xinos_",
  "Fetterike",
  "GiorgioG02",
  "Blackstaff_McKoy",
  "McPandapants",
  "Apollo_993",
  "FearlessDazzler",
  "Jay_The_Ay",
  "ASOneiroi",
  "MangoGam1ng",
  "cnivek80",
  "MarcosSalinas",
  "Guudd",
  "Saber5",
  "Rick_mc_",
  "SKYKING4000",
  "confus3dd",
  "Stxrry_zky",
  "JB_Ratz",
  "1Nathan",
  "Dreadelord",
  "xWadge",
  "EjRenegade",
  "catfood2",
  "Matijo9",
  "Nalcot2",
  "myrngsucks",
  "GuerillaMill",
  "Twistyeys",
  "LeHe_21",
  "Linkdash",
  "SarahMolly",
  "illimay",
  "LordMeowMix123",
  "formalkilling",
  "UprightMirror46",
  "EaterOfRears",
  "Kamisanbi",
  "cyndasaur",
  "Hiewzy4",
  "slumfest",
  "smorelax",
  "EmmiesMom",
  "FalkoderFalko",
  "Brad_BreadStick",
  "TnTJustus",
  "Criticalpact",
  "LarsUlrich66",
  "_Gli1tch",
  "tvattbj0rn",
  "Bobehead42069",
  "JensenCranks90s",
  "Arion955",
  "beatlecraft",
  "amorchts",
  "wittywolf",
  "original_pyro",
  "DeimosRU",
  "Ali_Alasfoor",
  "Fat_Man_Ian",
  "TSGXeNoZz",
  "s2dbaker",
  "Marhau",
  "MiniatureMango",
  "silverfishies",
  "brogrammer_",
  "bbenz00",
  "SpaceHerpies",
  "mythologists",
  "RoboticCon",
  "BirdPick",
  "PsukheKratos",
  "HD_Pixels",
  "millanatato",
  "WaitImaGoat",
  "DaRealDIOBrando",
  "Yolkyraindrop",
  "Sar2417",
  "TehNumberOne",
  "Grufted",
  "MajesticFatCatX",
  "Doriok0817",
  "Jhonwillzzz",
  ".Kaderade7694",
  "KujouLunar",
  "DuccGoQuaccc",
  "estroloaben777",
  "thumpyg",
  "Mike_Leetoris",
  "bboofd",
  "OkayAshh",
  "jordan_e",
  "Da29767",
  "C0pp3rnicus",
  "BugsBunny93",
  "SkiDude1119",
  "RobinCould",
  "LETSGOOOOOO0",
  "SuperDrangus",
  "EliteAwesome",
  "lukemac9140",
  "zeppelin262",
  "afancypenguin",
  "mikdom23",
  "richelie",
  ".SinningLlama439",
  "nqft",
  "ferrets01",
  "KingWriteThe3rd",
  "AcmeYou",
  "haildamage10",
  "tgallagher",
  "Dinnerbone3845",
  "cwedin78",
  "arachnoscarab",
  "jillilizard",
  "little_android",
  "tupahaot",
  "c0ffee31",
  "TellLifeThatHe",
  "Thunderism",
  "DrShapo",
  "PhoenixFire108",
  "urahoexoxo",
  "xCovey",
  "Kroh",
  "GlassyCat705659",
  "Markusknarkus05",
  ".daydream58",
  "REDdog999",
  "BaronHarkozyn",
  "19Schoba98",
  "cool_little_guy",
  "Genwey",
  ".BradyHughes",
  "Quantum_Fields",
  ".GlitteryPuddle3",
  "XmalachiteX",
  "pinkitys",
  "Souperior1337",
  "DoctorD3645",
  "BrodyWavecrusher",
  ".MechanicalX3",
  "LeLemonLime",
  "Nathalius",
  "JakobF2013",
  "YourNormalPlayr",
  "NaturallyCastle",
  "Angels4Angels",
  "DayHen803069126",
  "Litiagame",
  "plat_e",
  "JJU888",
  "NeXxBerry",
  ".TheChayce900",
  "oldfrend",
  "MonsterMetzker",
  "mackint11",
  "Blackbearpnh",
  "Litchytsu",
  "Memonon",
  "Shi_Death",
  "Starrydash",
  "Dandy_Devil",
  "edmeedy",
  "weaponizedzest",
  "sheepinasuit394",
  "ColorMarvin",
  "Darkflame143",
  "Ahbadoo",
  "HadesnoKami",
  "StaceysMomy",
  "SuperDan3",
  "Jacek61Tor",
  "gbital",
  "Insanity946",
  "eddie_is_shadow",
  "Marius2025_super",
  "emty_void763",
  "EmgXErgen",
  "GoldenChance9",
  "Sxphiie",
  "Crittertech",
  "lolkyuki123",
  "Turkari",
  "nyyxursobad",
  "TheBlessedTomato",
  "smittieTheHedge",
  "NitroDoggo",
  "DondaLemons",
  "wingman300",
  "eternitti",
  "Username2315",
  "SnackuzTV",
  "Eldor444",
  "Gibahgaming1234",
  "meliodasmmm",
  "4201269",
  "T_r_e_n_d",
  "7wood",
  "CorneliaVnGorder",
  "Manekk98",
  "destinpig",
  "ChrisTheStoic",
  "gejm3r",
  "sylixv",
  "SoyAbMolon",
  "BigLoque",
  "harley09alex",
  "LimeBread27",
  "BurtonGus69",
  "AmokLe",
  "joshi77",
  "BarmyGraph79762",
  "KingKiller1999",
  "HappyBean3",
  "HolyHalos",
  "MrcyberMC",
  "Broleary",
  "KorruptGG1",
  "Zephyrus360",
  "KiralyYork06",
  "Naruuu754",
  "Birddeh",
  "Lionracer11",
  ".ExposedAlloy493",
  "grasseater67",
  "sexyguy431",
  "Hunterfighter55",
  "Hans58",
  "Lucy_P",
  "LanderPVP",
  "ikeae",
  "Mayo3946",
  "omerixx",
  "KittenCuddler15",
  "LinkToTheFuture",
  "Creata1234s",
  "Fantastiola",
  "MKtwin2000",
  "builder239",
];

const MinecraftHeads = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPlayers, setFilteredPlayers] = useState(SAMPLE_PLAYERS);
  const [hoveredPlayer, setHoveredPlayer] = useState(null);
  const [page, setPage] = useState(1);
  const playersPerPage = 18;

  // Handle search input change and immediately filter results
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filter players based on search term
    const filtered = SAMPLE_PLAYERS.filter((username) =>
      username.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredPlayers(filtered);
    setPage(1); // Reset to first page when searching
  };

  // Clear search
  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredPlayers(SAMPLE_PLAYERS);
    setPage(1); // Reset to first page when clearing search
  };

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Calculate current page's players
  const indexOfLastPlayer = page * playersPerPage;
  const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
  const currentPlayers = filteredPlayers.slice(
    indexOfFirstPlayer,
    indexOfLastPlayer
  );
  const totalPages = Math.ceil(filteredPlayers.length / playersPerPage);

  return (
    <div>
      <Box>
        <Typography variant="h3" component="h1" gutterBottom align="left">
          MCV Citizens and friends
        </Typography>

        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <TextField
            fullWidth
            label="Search players"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Type to search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={20} />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <Typography
                    variant="caption"
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={handleClearSearch}
                  >
                    Clear
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Paper>

        {filteredPlayers.length === 0 ? (
          <Box textAlign="center" py={4}>
            <Typography variant="h6">
              No players found matching "{searchTerm}"
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {currentPlayers.map((username, index) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                <Card
                  onMouseEnter={() => setHoveredPlayer(username)}
                  onMouseLeave={() => setHoveredPlayer(null)}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      boxShadow: (theme) => theme.shadows[8],
                    },
                    width: "100%",
                    position: "relative",
                    // Fixed minimum height to prevent shrinking when hovering
                    minHeight: 180,
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      pt: hoveredPlayer === username ? 0 : 2,
                      height: hoveredPlayer === username ? "100%" : "auto",
                      width: "100%",
                      position:
                        hoveredPlayer === username ? "absolute" : "relative",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: hoveredPlayer === username ? 2 : 1,
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={
                        hoveredPlayer === username
                          ? `https://mc-heads.net/player/${username}/100`
                          : `https://mc-heads.net/avatar/${username}/80`
                      }
                      alt={`${username}'s head`}
                      sx={{
                        height: hoveredPlayer === username ? "auto" : 80,
                        width: hoveredPlayer === username ? "auto" : 80,
                        maxHeight: hoveredPlayer === username ? "100%" : 80,
                        maxWidth: hoveredPlayer === username ? "100%" : 80,
                        objectFit: "contain",
                        imageRendering: "pixelated",
                        padding: hoveredPlayer === username ? "10px" : 0,
                        // Ensure the full body image doesn't get too small
                        minHeight: hoveredPlayer === username ? 160 : "auto",
                        minWidth: hoveredPlayer === username ? 80 : "auto",
                      }}
                    />
                  </Box>
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      textAlign: "center",
                      visibility:
                        hoveredPlayer === username ? "hidden" : "visible",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    {hoveredPlayer !== username && (
                      <Typography variant="subtitle1" component="div" noWrap>
                        {username}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Box mt={4} textAlign="center">
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Showing {indexOfFirstPlayer + 1}-
            {Math.min(indexOfLastPlayer, filteredPlayers.length)} of{" "}
            {filteredPlayers.length} players
          </Typography>

          {totalPages > 1 && (
            <Stack spacing={2} alignItems="center" sx={{ mt: 3 }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
              />
            </Stack>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default MinecraftHeads;
