import React from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Link,
} from "@mui/material";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ExploreIcon from "@mui/icons-material/Explore";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import StarIcon from "@mui/icons-material/Star";
import PaidIcon from "@mui/icons-material/Paid";

function Rules() {
  return (
    <Box sx={{ flexGrow: 1, py: 4 }}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Server Rules
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 3 }}>
            The server encourages a clean and respectful public chat. Disputes
            should be resolved in local chat or on discord, privately. <br />
          </Typography>
          <Typography variant="body1" paragraph>
            MCVerse allows for many different play styles. It is important to be
            mindful of other player's play styles and respect their boundaries.
          </Typography>

          <List>
            <ListItem>
              <ListItemIcon>
                <LocalPoliceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Be Respectful and Keep Chat Clean"
                secondary="Treat all players with respect. Harassment, discrimination, and offensive language are not tolerated. Pranks should be among friends not strangers."
              />
            </ListItem>
            <Divider variant="inset" component="li" />

            <ListItem>
              <ListItemIcon>
                <LocalPoliceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="No Griefing - Claim Your Land"
                secondary="Do not destroy or alter other players' builds without permission. Respect others' property and hard work."
              />
            </ListItem>
            <Divider variant="inset" component="li" />

            <ListItem>
              <ListItemIcon>
                <LocalPoliceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Rights to Claim"
                secondary="Established (Strong) claims are generally permanent. Avoid settling within 300 blocks without permission."
              />
            </ListItem>
            <Divider variant="inset" component="li" />

            <ListItem>
              <ListItemIcon>
                <LocalPoliceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="No Cheating or Hacking"
                secondary="Using mods or clients that play for you or provide unfair advantages is highly discouraged. This includes X-ray, fly hacks, bots, etc. Dupes are not permitted."
              />
            </ListItem>
            <Divider variant="inset" component="li" />

            <ListItem>
              <ListItemIcon>
                <LocalPoliceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="No Lag Machines or Crash Exploits"
                secondary="Keep farms to a reasonable size and with on/off switches. Chunk loading is currently discouraged but an update is planned."
              />
            </ListItem>
            <Divider variant="inset" component="li" />

            <ListItem>
              <ListItemIcon>
                <LocalPoliceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="No Spam"
                secondary="Excessive messaging, advertising, or flooding chat is not allowed. Use local chat, party chat, or whispers to have conversations."
              />
            </ListItem>
            <Divider variant="inset" component="li" />

            <ListItem>
              <ListItemIcon>
                <LocalPoliceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Follow Staff Instructions"
                secondary="Use the self-serve tools to protect yourself. Please follow staff instructions. If you have questions contact via discord."
              />
            </ListItem>
          </List>

          <Typography variant="body1" paragraph sx={{ mt: 3 }}>
            Failure to follow these rules may result in warnings, temporary
            bans, or permanent bans, depending on the severity and frequency of
            the violations.
          </Typography>

          <Typography variant="body1" paragraph>
            If you have any questions about these rules or would like to report
            a rule violation, please contact our staff through our Discord
            server.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
}

export default Rules;
