import React from "react";
import { Box, Container, Typography, Paper } from "@mui/material";
import CustomFishSubmission from "../Components/CustomFishSubmission";

function FishSubmission() {
  return (
    <Box sx={{ flexGrow: 1, py: 4 }}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Submit Your Custom Fish
          </Typography>
          <Typography variant="body1" paragraph align="center" sx={{ mb: 4 }}>
            Use this form to submit your custom fish for the MCVerse server. Provide your Minecraft username, 
            fish name, description, and the Base64 texture for the fish head.
          </Typography>
          <CustomFishSubmission />
        </Paper>
      </Container>
    </Box>
  );
}

export default FishSubmission; 