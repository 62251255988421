import React from "react";
import { Box, Container, Typography, Paper } from "@mui/material";
import MinecraftPlayerHeads from "../Components/MinecraftPlayerHeads";

function Players() {
  return (
    <Box sx={{ flexGrow: 1, py: 4 }}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <MinecraftPlayerHeads></MinecraftPlayerHeads>
        </Paper>
      </Container>
    </Box>
  );
}

export default Players;
