import React from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

/**
 * A reusable component for guide sections in the MCVerse website
 *
 * @param {Object} props
 * @param {React.ReactNode} props.icon - The icon component to display
 * @param {string} props.title - The title of the section
 * @param {Array} props.items - Array of items to display in the list
 * @param {string} props.id - Optional id for the section (for anchor links)
 * @param {Object} props.sx - Optional style overrides
 */
function GuideSection({ icon, title, items = [], id, sx = {} }) {
  return (
    <Paper
      elevation={3}
      sx={{ p: 3, borderRadius: "8px", height: "100%", ...sx }}
      id={id}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        {icon && React.cloneElement(icon, {
          sx: { color: "primary.main", mr: 1.5, fontSize: 28 },
        })}
        <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>
          {title || "No Title"}
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <List dense disablePadding>
        {items && items.length > 0 ? items.map((item, index) => (
          <ListItem key={index} sx={{ pb: index < items.length - 1 ? 1 : 0 }}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              {item && item.customIcon ? (
                React.cloneElement(item.customIcon, { fontSize: "small" })
              ) : (
                <ArrowRightIcon color="primary" fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={item && item.text ? item.text : "No content"}
              primaryTypographyProps={item && item.textProps ? item.textProps : {}}
            />
          </ListItem>
        )) : (
          <ListItem>
            <ListItemText primary="No items to display" />
          </ListItem>
        )}
      </List>
    </Paper>
  );
}

export default GuideSection;
