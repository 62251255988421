import React from 'react';
import { Box, Container, Typography, Paper } from '@mui/material';

function Map() {
  return (
    <Box sx={{ flexGrow: 1, py: 4 }}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            26K Days Permanent Survival Map
          </Typography>
          <Typography variant="body1" paragraph>
            Use the map to find your way around.
          </Typography>
          <Typography variant="body1" paragraph>
            Legend:
            <list>
              <li> STARS - Player Warps you can teleport to using /pwarp
              </li>
            </list>
          </Typography>
          
          <Box
            component="iframe"
            src="https://map.mcverse.city/"
            sx={{
              width: '100%',
              height: '70vh',
              border: 'none',
              borderRadius: '4px',
              mt: 2,
              mb: 2,
              boxShadow: 2
            }}
            title="MCVerse Server Map"
            allowFullScreen
          />
        </Paper>
      </Container>
    </Box>
  );
}

export default Map;