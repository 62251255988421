import React from "react";
import { Box, Typography, Link } from "@mui/material";

/**
 * A reusable component for displaying guide content links
 * 
 * @param {Object} props
 * @param {Array} props.sections - Array of section objects to display
 * @param {string} props.sections[].title - The title of the section
 * @param {Array} props.sections[].links - Array of link items in the section
 * @param {string} props.sections[].links[].text - The text to display for the link
 * @param {string} props.sections[].links[].href - The href/anchor to link to
 * @param {Object} props.sx - Optional style overrides
 */
function GuideContents({ sections, sx = {} }) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4, mt: 3, ...sx }}>
      {sections.map((section, index) => (
        <Box key={index} sx={{ minWidth: 200 }} id={section.id}>
          <Typography variant="h6" gutterBottom>
            {section.title}
          </Typography>
          {section.links.map((link, linkIndex) => (
            <Typography key={linkIndex} component="div">
              <Link href={link.href} underline="hover" color="primary">
                • {link.text}
              </Link>
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
}

export default GuideContents; 