import React, { useState, useEffect } from "react";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  ComposedChart,
} from "recharts";
import {
  Card,
  CardHeader,
  Typography,
  Box,
  Autocomplete,
  TextField,
  CircularProgress,
  Grid,
  Divider,
  useTheme,
  alpha,
  Chip,
  IconButton,
} from "@mui/material";
import Papa from "papaparse";
import FilterListIcon from "@mui/icons-material/FilterList";
import StorefrontIcon from "@mui/icons-material/Storefront";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const MarketsDataByMonth = ({ title = "Market Data Stats", subtitle = "" }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Use fetch API instead of window.fs which doesn't exist in browser JavaScript
        const response = await fetch("/markets-data/2025-04-07-by-month.tsv");
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }

        const text = await response.text();

        // Parse TSV data
        Papa.parse(text, {
          delimiter: "\t",
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true, // Convert numeric values to numbers
          complete: (results) => {
            if (results.errors.length > 0) {
              setError(`Error parsing TSV: ${results.errors[0].message}`);
              return;
            }

            // Process the data
            const parsedData = results.data;

            // Extract unique item types and format as objects
            const uniqueTypes = [
              ...new Set(parsedData.map((item) => item.item_type)),
            ].sort();
            const formattedTypes = uniqueTypes.map((type) => ({ label: type }));
            setItemTypes(formattedTypes);

            // Set default selected item type
            if (formattedTypes.length > 0) {
              setSelectedItemType(formattedTypes[0]);
            }

            setData(parsedData);
            setIsLoading(false);
          },
          error: (error) => {
            setError(`Error parsing TSV: ${error.message}`);
            setIsLoading(false);
          },
        });
      } catch (error) {
        setError(`Error loading file: ${error.message}`);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Helper function to safely get label from selectedItemType
  const getSelectedItemTypeLabel = () => {
    return selectedItemType && selectedItemType.label
      ? selectedItemType.label
      : "";
  };

  useEffect(() => {
    if (selectedItemType && data.length > 0) {
      // Filter data by selected item type
      const filtered = data.filter(
        (item) => item.item_type === getSelectedItemTypeLabel()
      );

      if (filtered.length > 0) {
        // Find the min and max dates to ensure we include all months
        const dates = filtered.map((item) => new Date(item.month));
        const minDate = new Date(Math.min(...dates));

        // Instead of using max date from data, use current date to ensure forward filling
        const maxDateFromData = new Date(Math.max(...dates));
        const currentDate = new Date();
        // Use the greater of the two - either the max date from data or current date
        const maxDate =
          maxDateFromData > currentDate ? maxDateFromData : currentDate;

        // Create a map of existing data points by month
        const dataByMonth = {};
        filtered.forEach((item) => {
          dataByMonth[item.month] = item;
        });

        // Generate a complete month sequence
        const completeData = [];
        let iterationDate = new Date(minDate);

        // Set date to first day of month for consistent comparison
        iterationDate.setDate(1);
        const endDate = new Date(maxDate);
        endDate.setDate(1);

        while (iterationDate <= endDate) {
          const monthStr = iterationDate.toISOString().substring(0, 7); // Format: YYYY-MM

          if (dataByMonth[monthStr]) {
            // Use existing data if available
            completeData.push(dataByMonth[monthStr]);
          } else {
            // Create a placeholder for missing months
            completeData.push({
              month: monthStr,
              item_type: getSelectedItemTypeLabel(),
              total_transaction_value: 0,
              avg_price_per_unit: 0,
              total_units: 0,
              transaction_count: 0,
            });
          }

          // Move to next month
          iterationDate.setMonth(iterationDate.getMonth() + 1);
        }

        // Sort by month
        completeData.sort((a, b) => new Date(a.month) - new Date(b.month));
        setFilteredData(completeData);
      } else {
        setFilteredData([]);
      }
    }
  }, [selectedItemType, data]);

  const handleItemTypeChange = (event, newValue) => {
    if (newValue === null && itemTypes.length > 0) {
      setSelectedItemType(itemTypes[0]);
    } else {
      setSelectedItemType(newValue);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "error.main",
        }}
      >
        {error}
      </Box>
    );
  }

  // Customize the tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card sx={{ p: 1.5, boxShadow: 2, bgcolor: "background.paper" }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {label}
          </Typography>
          {payload.map((entry, index) => (
            <Box key={index} sx={{ mb: 0.5 }}>
              <Typography
                variant="body2"
                sx={{
                  color: entry.color,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    bgcolor: entry.color,
                    mr: 1,
                    borderRadius: 0.5,
                  }}
                />
                {entry.name}: {entry.value}
              </Typography>
            </Box>
          ))}
        </Card>
      );
    }
    return null;
  };

  return (
    <Card elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
      <CardHeader
        title={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StorefrontIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            <Typography variant="h4">{title}</Typography>
          </Box>
        }
        subheader={subtitle}
        action={
          <Tooltip title="Market data statistics">
            <IconButton>
              <TrendingUpIcon />
            </IconButton>
          </Tooltip>
        }
      />

      <Divider sx={{ mb: 3 }} />

      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="item-type-filter-chart"
              options={itemTypes}
              value={selectedItemType}
              onChange={handleItemTypeChange}
              getOptionLabel={(option) => option.label || ""}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Item Type to View Trends"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <FilterListIcon
                          sx={{ color: "action.active", mr: 1 }}
                        />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {selectedItemType && (
              <Chip
                label={`Viewing trends for: ${getSelectedItemTypeLabel()}`}
                color="primary"
                variant="outlined"
                size="medium"
              />
            )}
          </Grid>
        </Grid>
      </Box>

      {filteredData.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={filteredData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.6} />
            <XAxis
              dataKey="month"
              tick={{ fill: theme.palette.text.primary }}
              tickLine={{ stroke: theme.palette.divider }}
              axisLine={{ stroke: theme.palette.divider }}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              tick={{ fill: theme.palette.text.primary }}
              tickLine={{ stroke: theme.palette.divider }}
              axisLine={{ stroke: theme.palette.divider }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tick={{ fill: theme.palette.text.primary }}
              tickLine={{ stroke: theme.palette.divider }}
              axisLine={{ stroke: theme.palette.divider }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              wrapperStyle={{
                paddingTop: "10px",
              }}
            />
            <Bar
              dataKey="total_transaction_value"
              name="Total Sales $"
              yAxisId="left"
              fill={alpha(theme.palette.primary.main, 0.7)}
              radius={[4, 4, 0, 0]}
            />
            <Line
              type="monotone"
              dataKey="avg_price_per_unit"
              name="Avg Price"
              yAxisId="right"
              stroke={theme.palette.secondary.main}
              strokeWidth={2}
              dot={{ r: 4, fill: theme.palette.secondary.main }}
              activeDot={{ r: 6 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <Box
          sx={{
            height: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: alpha(theme.palette.primary.main, 0.03),
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1" color="text.secondary">
            No data available for the selected item type
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default MarketsDataByMonth;
